import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../assets/seo.webp';
import AI from '../assets/ai.webp';
import Globe from '../assets/globe.png';

import ServicesCard from "./ServicesCard";
function ArtificialIntelligence(){
    

    return(
        <Container>
            <Row>
                <div className="lg-6 artificial-intelligence">
                    <h1>The Future is Artificial Intelligence</h1>
                    <h4>AI is transforming the way we work and live—don’t let the opportunity pass you by! Adopting AI now is like gaining a powerful new tool that streamlines your efforts and supercharges your results. Without it, you risk falling behind as others race ahead.</h4><br/>
                    <h4>We’re here to be your trusted partner, making AI accessible, understandable, and impactful. From initial strategy to full implementation, we simplify the process, turning cutting-edge technology into a winning advantage for your business.</h4><br/>
                    <h4>Ready to take the leap? Let’s build a smarter, AI-driven future together!</h4>
                </div>
                <div className="lg-6 globe">
                    <img src={Globe} style={{maxWidth: '100%'}}/>
                </div>
            </Row>
        </Container>
    )
}

export default ArtificialIntelligence;