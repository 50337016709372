import Card from 'react-bootstrap/Card';


function ServicesCard(props) {
  const {title, description, imageSource} = props;
  return (
    <Card style={{ width: '18rem' }} className='servicecard '>
      {/* <Card.Img variant="top" src={imageSource} /> */}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ServicesCard;