import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../assets/seo.webp';
import AI from '../assets/ai.webp';


import ServicesCard from "./ServicesCard";
function Services(){
    const deleteMe = "Some quick example text to build on the card title and make up the bulk of the card's content."
    const services = [
        {
        "title": "AI Integration",
        "description": "Enhance customer engagement with our AI chatbot services! Provide instant support, improve user experience, and boost sales 24/7. Transform your customer interactions today!",
        "imageSource": AI
        },
        {
        "title": "Search Engine Optimization",
        "description": "Optimize your website to rank higher on search engines, attract more customers, and increase conversions. Start maximizing your online potential!  ",
        "imageSource": SEO
        },
        {
        "title": "Web Development",
        "description": "Transform your online presence with our web development services! Create stunning, user-friendly websites that drive traffic, boost sales, and enhance customer engagement. ",
        "imageSource": SEO
        },
        // {
        // "title": "Marketing",
        // "description": "Elevate your brand with our expert marketing, branding & copywriting services! Capture attention, drive conversions, and convey your unique message with compelling content!",
        // "imageSource": SEO
        // },
    ]

    return(
        <Container>
            <Row   className="services-row">
            {services.map((s) => {
                return (
                    <ServicesCard title={s.title} description={s.description} imageSource={s.imageSource}/>
                )
            })}

            </Row>
        </Container>
    )
}

export default Services;