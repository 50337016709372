import HeroSection from './HeroSection';
import Services from './Services';
import TransformSection from './TransformSection';
import TechCompany from './TechCompany';
import ArtificialIntelligence from './ArtificialIntelligence';
import Footer from './Footer';
function Home() {
  
  return (
    <div className="">
        <HeroSection/>
        <ArtificialIntelligence/>
        <Services/>
        <TechCompany/>
        <TransformSection/>
        <Footer/>
    </div>
  );
}

export default Home;
